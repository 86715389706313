<template>
  <v-container fluid>
    <v-radio-group v-model="locale" row>
      <v-radio v-for="(locale, index) in localesDictionary" :key="index" :label="locale.name" :value="locale.value"></v-radio>
    </v-radio-group>
    <v-row>
      <v-col cols="12" sm="9" md="5" lg="4">
        <div>
          <v-select
            v-model="selectedPackage"
            @change="selectPackage"
            :items="PACKAGE_TYPES"
            item-text="desc1"
            return-object
            label="Тип пакету, якому відповідає подія"
            @input="$v.selectedPackage.type.$touch()"
            @blur="$v.selectedPackage.type.$touch()"
            :error-messages="packageErrors"
          ></v-select>
        </div>
        <div>
          <v-select
            v-model="editedItem.protocol"
            :items="PROTOCOL_TYPES"
            item-text="type"
            item-value="type"
            label="Протокол, якому відповідає подія"
            clearable
            @input="$v.editedItem.protocol.$touch()"
            @blur="$v.editedItem.protocol.$touch()"
            :error-messages="protocolErrors"
          ></v-select>
        </div>
        <div>
          <v-text-field
            v-model="editedItem.name[locale]"
            :label="labelName"
            clearable
            @input="$v.editedItem.name[$currentLocale].$touch()"
            @blur="$v.editedItem.name[$currentLocale].$touch()"
            :error-messages="nameErrors"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="editedItem.event_code"
            :label="labelCode"
            clearable
            @input="$v.editedItem.event_code.$touch()"
            @blur="$v.editedItem.event_code.$touch()"
            :error-messages="codeErrors"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="9" md="5" lg="4">
        <div>
          <v-select
            v-model="editedItem.device_type"
            :items="machinesTypesDictionary"
            item-text="description"
            item-value="value"
            label="Пристрій, якому відповідає подія"
            clearable
            @input="$v.editedItem.device_type.$touch()"
            @blur="$v.editedItem.device_type.$touch()"
            :error-messages="deviceErrors"
          >
            <template v-slot:selection="{ item }">{{ item.description }} ({{ item.name }})</template>
            <template v-slot:item="{ item }">{{ item.description }} ({{ item.name }})</template>
          </v-select>
        </div>
        <div class="mt-10">
          <v-textarea v-model="editedItem.short_description[locale]" label="Пояснення*" outlined clearable></v-textarea>
          <div class="text-caption text-justify text--disabled">
            * Текстове пояснення події, що розкриває логічну суть цієї події простими словами. Може використовуватися як при при
            при наведення курсору на подію/статус на фронті.
          </div>
        </div>
      </v-col>
      <v-col v-if="selectedPackage.type === 1" cols="12" sm="9" md="10" lg="4">
        <div class="mb-3">
          <span>Колір статуса:</span>
          <input
            v-if="editedItem.colour"
            class="ml-2"
            type="color"
            :value="editedItem.colour"
            disabled
            style="width: 36px; height: 16px"
          />
        </div>
        <v-sheet class="pa-4" width="250" rounded outlined>
          <div v-for="(item, index) in SWATCHES" :key="index" class="d-flex align-center ma-1">
            <v-btn @click="selectColor(item.color, index)" tile :color="item.color" x-small height="16" class="mr-3">
              <v-icon v-show="selectedColorIndex === index" x-small>mdi-check-bold</v-icon>
            </v-btn>
            <span class="text-caption">{{ item.description }}</span>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-container fluid>
      <div class="d-flex justify-end">
        <v-btn @click="$router.go(-1)" class="mr-2" small>Назад</v-btn>
        <v-btn @click="openDialog" color="primary" small>Зберегти</v-btn>
      </div>
    </v-container>
    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="saveData" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { PACKAGE_TYPES } from '@/const/constructor/packages-types.enum'
import { PROTOCOL_TYPES } from '@/const/constructor/protocol-types.enum'
import { ADDITIONAL_INFO_EVENT_CODE_SCHEMA } from '@/const/apiSchemas'
import { COLOR_SWATCHES } from '@/const/constructor/color-swatches.enum'
import CloneDeep from '@/mixins/cloneDeep'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { CURRENT_LOCALE } from '@/const/globals'

export default {
  name: 'EventConstructor',

  mixins: [CloneDeep, validationMixin],

  validations: {
    editedItem: {
      protocol: { required },
      name: {
        [CURRENT_LOCALE]: { required },
      },
      event_code: { required },
      device_type: { required },
    },
    selectedPackage: {
      type: { required },
    },
  },

  components: {
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ADDITIONAL_INFO_EVENT_CODE_SCHEMA: ADDITIONAL_INFO_EVENT_CODE_SCHEMA,
    PACKAGE_TYPES: PACKAGE_TYPES,
    PROTOCOL_TYPES: PROTOCOL_TYPES,
    SWATCHES: COLOR_SWATCHES,
    selectedPackage: {},
    editedItem: {},
    defaultItem: {},
    isLoading: false,
    showModal: false,
    locale: '',
    selectedColorIndex: null,
  }),

  computed: {
    ...mapState('infoConstructor', ['additionalInfoEvent', 'additionalInfoSelectedEventType']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),
    ...mapState('dictionaries', ['localesDictionary']),
    ...mapGetters('profile', ['getCurrentUserLocale']),

    packageErrors() {
      const errors = []
      if (!this.$v.selectedPackage.type.$dirty) return errors
      !this.$v.selectedPackage.type.required && errors.push('Це поле обов"язкове')
      return errors
    },
    protocolErrors() {
      const errors = []
      if (!this.$v.editedItem.protocol.$dirty) return errors
      !this.$v.editedItem.protocol.required && errors.push('Це поле обов"язкове')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name[this.$currentLocale].$dirty) return errors
      !this.$v.editedItem.name[this.$currentLocale].required && errors.push('Це поле обов"язкове')
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.editedItem.event_code.$dirty) return errors
      !this.$v.editedItem.event_code.required && errors.push('Це поле обов"язкове')
      return errors
    },
    deviceErrors() {
      const errors = []
      if (!this.$v.editedItem.device_type.$dirty) return errors
      !this.$v.editedItem.device_type.required && errors.push('Це поле обов"язкове')
      return errors
    },
    eventTypeId() {
      return this.$route.params.eventTypeId
    },
    labelName() {
      return this.selectedPackage?.desc2
        ? `Назва ${this.selectedPackage.desc2} згідно протоколу`
        : 'Назва подіїї згідно протоколу'
    },
    labelCode() {
      return this.selectedPackage?.desc2 ? `Код ${this.selectedPackage.desc2} згідно протоколу` : 'Код події згідно протоколу'
    },
  },

  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.locale = this.getCurrentUserLocale
      if (this.isEditable) {
        this.editedItem = this.cloneObjectDeep(this.additionalInfoEvent)
        this.selectedPackage = this.PACKAGE_TYPES.find((item) => item.type === this.editedItem.packet_event_type)
        this.$emit('selectPackage', this.selectedPackage)
      } else {
        this.editedItem = this.cloneObjectDeepWithLocales(this.ADDITIONAL_INFO_EVENT_CODE_SCHEMA, this.localesDictionary)
        this.editedItem.packet_event_type = this.selectedPackage.type
      }
      this.editedItem.event_type.id = this.eventTypeId
    },
    selectPackage() {
      this.$emit('selectPackage', this.selectedPackage)
      this.editedItem.packet_event_type = this.selectedPackage.type
    },
    openDialog() {
      this.$v.selectedPackage.$touch()
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.showModal = true
    },
    saveData() {
      this.$emit('saveData', this.editedItem)
    },
    selectColor(color, index) {
      this.editedItem.colour = color
      this.selectedColorIndex = index
    },
  },
}
</script>

<style scoped lang="scss">
.v-color-picker.theme--light.v-sheet {
  background-color: transparent;
  border: 1px solid #898b8c;
}
/*::v-deep .v-color-picker .v-color-picker__sliders {
  pointer-events: none;
}*/
</style>

export const COLOR_SWATCHES = Object.freeze([
  { color: '#14D2FB', description: 'Зайнятий' },
  { color: '#FD9D9D', description: 'Помилка' },
  { color: '#325928', description: 'Включений' },
  { color: '#808295', description: 'Немає зв’язку' },
  { color: '#13FA7D', description: 'Готовий до роботи' },
  { color: '#FDC80D', description: 'Інший статус' },
  { color: '#FB8314', description: 'Інший статус' },
  { color: '#FF103B', description: 'Фатальна помилка' },
  { color: '', description: 'Без кольору' },
])
